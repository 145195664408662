import React from 'react';
import { translate } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';

class AlertConfirm extends React.Component {
  render() {
    let { t } = this.props;
    let title = t('Are you sure?');
    let cancelBtn = t('Cancel');
    let confirmBtn = t('Accept');

    return (
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title={title}
        onConfirm={this.props.onConfirm}
        onCancel={this.props.onCancel}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="default"
        confirmBtnText={confirmBtn}
        cancelBtnText={cancelBtn}
        showCancel
      >
        {t(this.props.message)}
      </SweetAlert>
    );
  }
}

export default translate('translations-fr')(AlertConfirm);
