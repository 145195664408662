import React, { Component } from 'react';
import styles from './styles';
import Star from './elements/star';

import Konva from 'konva';
import {
  Stage,
  Layer,
  Line,
  Text,
  Circle,
  Wedge,
  Image as ImgKonva
} from 'react-konva';
import Buttons from './buttons';
import ButtonSave from './buttonSave';

Konva.pixelRatio = 1;
const notClickActions = ['line', 'form', 'draw'];

// https://konvajs.org/docs/react/Shapes.html

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isMobile: false,
      open: false,
      backgroundImage: '',
      imgObj: null,
      elementsData: [],
      saveAnimation: false,
      color: 'black',
      colors: [
        { name: 'black', rgb: [0, 0, 0] },
        { name: 'silver', rgb: [192, 192, 192] },
        { name: 'white', rgb: [255, 255, 255] },
        { name: 'red', rgb: [255, 0, 0] },
        { name: 'blue', rgb: [0, 0, 255] },
        { name: 'cyan', rgb: [0, 255, 255] },
        { name: 'green', rgb: [0, 128, 0] },
        { name: 'yellow', rgb: [255, 255, 0] }
      ],
      size: 4,
      opacity: 0.5,
      customElements: [],
      icons: {},
      newElement: {},
      containerSize: {
        w: 700,
        h: 600
      },
      imageSize: {
        w: 1000,
        h: 600
      },
      move: true,
      moveStart: false,
      selectedShape: '',
      stageAction: '',
      createText: null
    };
    this.cc = React.createRef();
    this.cdd = React.createRef();
    this.stage = React.createRef();
    this.layer = React.createRef();

    // used in scroll functionality
    this.lastDist = false;
    this.point = undefined;
    // used to creaet draws
    this.isPaint = false;
    this.lastLine = null;
  }

  componentWillMount() {
    let {
      backgroundImage,
      elementsData,
      customElements,
      icons,
      isMobile
    } = this.props;
    this.load({
      backgroundImage,
      elementsData,
      customElements,
      icons,
      isMobile
    });
  }

  componentWillReceiveProps({
    backgroundImage,
    elementsData,
    customElements,
    icons
  }) {
    this.load({ backgroundImage, elementsData, customElements, icons });
  }

  componentDidMount() {
    this.resizeStage();
  }

  /** Resize stage to fix Plan inside container width */
  resizeStage() {
    let { imageSize } = this.state;
    let width = this.cc.current.parentElement.clientWidth;
    let height = this.cc.current.parentElement.clientHeight;
    let stage = this.stage.current;
    let newScale = 1;
    // change image size to fix inside container width
    if (imageSize.w > width) {
      newScale = (100 * width) / imageSize.w;
    }
    if (imageSize.w < width) {
      newScale = (100 * imageSize.w) / width;
    }

    stage.absolutePosition({ x: 0, y: 0 });
    stage.scale({ x: newScale / 100, y: newScale / 100 });
    stage.batchDraw();

    this.setState(ps => ({
      ...ps,
      containerSize: {
        w: width,
        h: height
      },
      loading: true
    }));
  }

  load(data) {
    this.getImageData(data.backgroundImage)
      .then(imageData => {
        this.setState(
          ps => ({
            ...ps,
            imageSize: { w: imageData.w, h: imageData.h },
            imgObj: imageData.img,
            ...data,
            loading: false,
            saveAnimation: false
          }),
          () => {}
        );
      })
      .catch(err => {
        alert('Image not loaded');
      });
  }

  /** Get image information to set width and height of Stage */
  getImageData(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.onload = function() {
        resolve({ w: this.width, h: this.height, img });
      };
      img.src = url;
    });
  }

  /** Start moving element */
  handleDragStart = e => {
    let { stageAction } = this.state;
    if (stageAction === 'moveElement') {
      e.target.setAttrs({
        scaleX: 1.1,
        scaleY: 1.1
      });
    }
  };

  /** Stop moving element */
  handleDragEnd = (e, key) => {
    let { stageAction, elementsData } = this.state;
    if (stageAction === 'moveElement') {
      const index = elementsData.findIndex(ed => ed.options.key === key);
      if (index !== -1) {
        elementsData[index].options.x = e.target.attrs.x;
        elementsData[index].options.y = e.target.attrs.y;
        this.setState(ps => ({ ...ps, elementsData, saveAnimation: true }));
      }
    }
  };

  /** Select element from Stage */
  elementSelect = e => {
    let { elementsData, customElements } = this.state;
    let nep = elementsData.find(ep => ep.options.key === e.target.attrs.name);
    if (nep && nep.type) {
      if (customElements.find(ce => ce.type === nep.type)) {
        this.props.customElementSelect(nep);
      } else if (nep.type === 'text') {
        this.changeText(e);
      } else {
      }
    }
  };

  /** Delete element from Stage */
  elementDelete = e => {
    let { elementsData, customElements } = this.state;
    let dep = elementsData.find(ep => ep.options.key === e.target.attrs.name);
    elementsData = elementsData.filter(
      ep => ep.options.key !== e.target.attrs.name
    );

    if (dep && dep.type && customElements.find(ce => ce.type === dep.type))
      this.props.customElementDelete(dep, elementsData);

    this.setState(ps => ({ ...ps, elementsData, saveAnimation: true }));
  };

  /* Add new element inside Stage */
  addNewElement(options) {
    let { elementDefaultData } = this.props;
    let { elementsData, newElement, size, customElements } = this.state;
    let colorRgba = this.colorRgba();

    elementsData.push({
      ...elementDefaultData,
      type: newElement.type,
      options: {
        color: colorRgba,
        size,
        ...options,
        ...newElement.options
      }
    });

    let createText = null;
    if (newElement.type === 'text')
      createText = {
        size,
        color: colorRgba,
        value: '',
        top: this.stage.current.getStage().getPointerPosition().y + 'px',
        left: this.stage.current.getStage().getPointerPosition().x + 'px',
        name: options.key
      };

    this.setState(
      ps => ({ ...ps, elementsData, createText, saveAnimation: true }),
      () => {
        if (customElements.find(ce => ce.type === newElement.type))
          this.props.customElementCreate(elementsData);
      }
    );
  }

  overElement(key) {}

  outElement() {}

  /* Update text inside textarea, text inline editor */
  updateText(value) {
    this.setState(ps => ({
      ...ps,
      createText: {
        ...ps.createText,
        value
      }
    }));
  }

  /* Save text */
  finishTextEdition = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      let { createText, elementsData } = this.state;
      const index = elementsData.findIndex(
        ed => ed.options.key === createText.name
      );
      if (index !== -1) {
        elementsData[index].options.text = createText.value;
        this.setState(ps => ({
          ...ps,
          elementsData,
          createText: null,
          saveAnimation: true
        }));
      }
    }
  };

  /* Create textarea object to edit text inline and update element */
  changeText = e => {
    let textPosition = e.target.getAbsolutePosition();

    let areaPosition = {
      x: textPosition.x,
      y: textPosition.y + 40
    };

    let createText = {
      value: e.target.text(),
      top: areaPosition.y + 'px',
      left: areaPosition.x + 'px',
      width: e.target.width(),
      name: e.target.attrs.name
    };

    this.setState(ps => ({ ...ps, createText }));
  };

  /* Create Canva element from object */
  buildElement(e, type, last = false) {
    let { color, stageAction } = this.state;
    let elementKey = e.options.key;
    switch (type) {
      case 'start':
        return (
          <Star
            key={elementKey}
            name={elementKey}
            x={e.options.x}
            y={e.options.y}
            fill={e.options.color || color}
            draggable={stageAction === 'moveElement' ? true : false}
            elementClick={this.onClickStage}
            onClick={this.onClickStage}
            onTap={this.onClickStage}
            handleDragStart={evt => this.handleDragStart(evt, elementKey)}
            handleDragEnd={evt => this.handleDragEnd(evt, elementKey)}
            transformsEnabled={'position'}
            perfectDrawEnabled={false}
            listening={false}
          />
        );
      case 'line':
        return (
          <Line
            key={elementKey}
            name={e.options.key}
            x={e.options.x}
            y={e.options.y}
            draggable={stageAction === 'moveElement' ? true : false}
            points={e.options.points}
            tension={0}
            closed
            stroke={e.options.color || color}
            strokeWidth={e.options.size || 10}
            lineCap={'round'}
            lineJoin={'round'}
            elementClick={this.onClickStage}
            onClick={this.onClickStage}
            onTap={this.onClickStage}
            onMouseOver={() => this.overElement(e.options.key)}
            onMouseOut={() => this.outElement()}
            onDragStart={evt => this.handleDragStart(evt, elementKey)}
            onDragEnd={evt => this.handleDragEnd(evt, elementKey)}
            transformsEnabled={'position'}
            perfectDrawEnabled={false}
            //listening={false}
          />
        );
      case 'form':
        return (
          <Line
            key={elementKey}
            name={e.options.key}
            x={e.options.x}
            y={e.options.y}
            draggable={stageAction === 'moveElement' ? true : false}
            points={e.options.points}
            tension={0}
            closed
            stroke={e.options.color || color}
            strokeWidth={2}
            lineCap={'round'}
            lineJoin={'round'}
            fillLinearGradientStartPoint={{ x: -50, y: -50 }}
            fillLinearGradientEndPoint={{ x: 50, y: 50 }}
            fillLinearGradientColorStops={[0, e.options.color || color]}
            elementClick={this.onClickStage}
            onClick={this.onClickStage}
            onTap={this.onClickStage}
            onMouseOver={() => this.overElement(e.options.key)}
            onMouseOut={() => this.outElement()}
            onDragStart={evt => this.handleDragStart(evt, elementKey)}
            onDragEnd={evt => this.handleDragEnd(evt, elementKey)}
            transformsEnabled={'position'}
            perfectDrawEnabled={false}
          />
        );
      case 'text':
        return (
          <Text
            key={elementKey}
            name={e.options.key}
            x={e.options.x}
            y={e.options.y}
            draggable={stageAction === 'moveElement' ? true : false}
            text={e.options.text || ''}
            fontSize={e.options.size}
            fill={e.options.color || color}
            elementClick={this.onClickStage}
            onClick={this.onClickStage}
            onTap={this.onClickStage}
            onMouseOver={() => this.overElement(e.options.key)}
            onMouseOut={() => this.outElement()}
            onDragStart={evt => this.handleDragStart(evt, elementKey)}
            onDragEnd={evt => this.handleDragEnd(evt, elementKey)}
            transformsEnabled={'position'}
          />
        );
      case 'draw':
        return (
          <Line
            key={elementKey}
            name={e.options.key}
            draggable={stageAction === 'moveElement' ? true : false}
            points={e.options.points}
            tension={0.1}
            closed={false}
            stroke={e.options.color || color}
            strokeWidth={e.options.size || 2}
            lineCap={'round'}
            lineJoin={'round'}
            elementClick={this.onClickStage}
            onClick={this.onClickStage}
            onTap={this.onClickStage}
            onMouseOver={() => this.overElement(e.options.key)}
            onMouseOut={() => this.outElement()}
            onDragStart={evt => this.handleDragStart(evt, elementKey)}
            onDragEnd={evt => this.handleDragEnd(evt, elementKey)}
            transformsEnabled={'position'}
            perfectDrawEnabled={false}
          />
        );
      case 'circle':
        return (
          <Circle
            key={elementKey}
            name={e.options.key}
            x={e.options.x}
            y={e.options.y}
            draggable={stageAction === 'moveElement' ? true : false}
            radius={10}
            fill={e.options.color || color}
            elementClick={this.onClickStage}
            onClick={this.onClickStage}
            onTap={this.onClickStage}
            onMouseOver={() => this.overElement(e.options.key)}
            onMouseOut={() => this.outElement()}
            shadowColor={this.props.shadowColor || 'black'}
            shadowBlur={this.props.shadowBlur || 10}
            shadowOpacity={this.props.shadowOpacity || 0.6}
            onDragStart={evt => this.handleDragStart(evt, elementKey)}
            onDragEnd={evt => this.handleDragEnd(evt, elementKey)}
            transformsEnabled={'position'}
            perfectDrawEnabled={false}
          />
        );
      case 'wedge':
        return (
          <Wedge
            key={elementKey}
            name={e.options.key}
            x={e.options.x}
            y={e.options.y}
            draggable={stageAction === 'moveElement' ? true : false}
            radius={e.options.radius || 40}
            angle={e.options.angle || 60}
            fill={e.options.color || color}
            stroke={'black'}
            strokeWidth={1}
            rotation={-120}
            elementClick={this.onClickStage}
            onClick={this.onClickStage}
            onTap={this.onClickStage}
            onMouseOver={() => this.overElement(e.options.key)}
            onMouseOut={() => this.outElement()}
            shadowColor={this.props.shadowColor || 'black'}
            shadowBlur={this.props.shadowBlur || 10}
            shadowOpacity={this.props.shadowOpacity || 0.6}
            onDragStart={evt => this.handleDragStart(evt, elementKey)}
            onDragEnd={evt => this.handleDragEnd(evt, elementKey)}
            transformsEnabled={'position'}
            perfectDrawEnabled={false}
          />
        );
      case 'fontAwesome':
        return (
          <Text
            key={elementKey}
            name={e.options.key}
            x={e.options.x}
            y={e.options.y}
            fontSize={'40'}
            fontFamily={'"Font Awesome 5 Pro"'}
            fontStyle={'bold'}
            stroke={'black'}
            text={`${e.options.icon}`}
            fill={e.options.stroke}
            draggable={stageAction === 'moveElement' ? true : false}
            elementClick={this.onClickStage}
            onClick={this.onClickStage}
            onTap={this.onClickStage}
            onMouseOver={() => this.overElement(e.options.key)}
            onMouseOut={() => this.outElement()}
            onDragStart={evt => this.handleDragStart(evt, elementKey)}
            onDragEnd={evt => this.handleDragEnd(evt, elementKey)}
            transformsEnabled={'position'}
          />
        );
      default:
        return null;
    }
  }

  /* Create Canvas elements from object list */
  buildElements(data = []) {
    let { elementsData, customElements } = this.state;
    if (!data.length) data = elementsData;
    let elements = [];

    data.forEach((e, index) => {
      // search if is a custom element to create the icon with specific form and color
      let nce = customElements.find(ce => ce.type === e.type);
      if (nce)
        elements.push(
          this.buildElement(
            { ...e, options: { ...e.options, color: nce.color } },
            nce.form
          )
        );
      else elements.push(this.buildElement(e, e.type));
    });

    return elements;
  }

  // Init object to create new element or clean all if is the same type
  addElement(type, options = {}) {
    let { newElement } = this.state;
    if (newElement && newElement.type === type) {
      this.cleanAll();
    } else {
      this.updateState('add', { type, options });
    }
  }

  elementTransform = e => {
    // clicked on stage - cler selection
    if (e.target === e.target.getStage()) {
      this.setState({
        selectedShape: ''
      });
      return;
    }

    // clicked on transformer - do nothing
    const clickedOnTransformer =
      e.target.getParent().className === 'Transformer';
    if (clickedOnTransformer) {
      return;
    }

    // find clicked rect by its name
    const name = e.target.name();
    const rect = this.state.elements.find(r => r.props.name === name);

    if (rect) {
      this.setState(ps => ({ ...ps, selectedShape: name }));
    } else {
      this.setState(ps => ({ ...ps, selectedShape: '' }));
    }
  };

  /** Handle all posible actions when click stage component */
  onClickStage = e => {
    let { move, stageAction, newElement } = this.state;
    if (!move) {
      // add new element to the page
      if (stageAction === 'add') {
        let mrp = this.mouseRelativePosition();
        this.addNewElement({
          key: `${newElement.type}-${mrp.x}-${mrp.y}-${Math.random()}`,
          ...mrp
        });
      } else if (stageAction === 'transform') {
        this.elementTransform(e);
      } else if (stageAction === 'delete') {
        this.elementDelete(e);
      } else if (stageAction === 'select') {
        this.elementSelect(e);
      }
    }
  };

  /** Send all the new elements to parent component */
  saveChanges() {
    let { elementsData } = this.state;
    this.props.saveData(elementsData);
  }

  /** create color in rgba for using color and opacity variables */
  colorRgba(colors = [], color = '', opacity = 0) {
    if (colors && color) {
      let colorObject = colors.find(c => c.name === color);
      return `rgba(${colorObject.rgb[0]},${colorObject.rgb[1]},${
        colorObject.rgb[2]
      }, ${opacity})`;
    } else {
      let colorObject = this.state.colors.find(
        c => c.name === this.state.color
      );
      return `rgba(${colorObject.rgb[0]},${colorObject.rgb[1]},${
        colorObject.rgb[2]
      }, ${this.state.opacity})`;
    }
  }

  getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  clientPointerRelativeToStage(clientX, clientY, stage) {
    return {
      x: clientX - stage.getContent().offsetLeft,
      y: clientY - stage.getContent().offsetTop
    };
  }

  pinchZoomWheelEvent = e => {
    let wheelEvent = e.evt;
    let stage = this.stage.current;

    wheelEvent.preventDefault();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();
    const startPos = {
      x: pointer.x / oldScale - stage.x() / oldScale,
      y: pointer.y / oldScale - stage.y() / oldScale
    };

    const deltaYBounded = !(wheelEvent.deltaY % 1)
      ? Math.abs(Math.min(-10, Math.max(10, wheelEvent.deltaY)))
      : Math.abs(wheelEvent.deltaY);
    const scaleBy = 1.01 + deltaYBounded / 70;
    const newScale =
      wheelEvent.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

    let height = newScale * stage.attrs.height;

    if (height > 300 && height < 5500) {
      stage.scale({ x: newScale, y: newScale });

      const newPosition = {
        x: (pointer.x / newScale - startPos.x) * newScale,
        y: (pointer.y / newScale - startPos.y) * newScale
      };
      stage.position(newPosition);
      stage.batchDraw();
    }
  };

  pinchZoomTouchEvent = e => {
    let evt = e.evt;
    let stage = this.stage.current;

    const t1 = evt.touches[0];
    const t2 = evt.touches[1];
    if (t1 && t2) {
      evt.preventDefault();
      evt.stopPropagation();
      const oldScale = stage.scaleX();

      const dist = this.getDistance(
        { x: t1.clientX, y: t1.clientY },
        { x: t2.clientX, y: t2.clientY }
      );
      if (!this.lastDist) this.lastDist = dist;
      const delta = dist - this.lastDist;

      const px = (t1.clientX + t2.clientX) / 2;
      const py = (t1.clientY + t2.clientY) / 2;
      const pointer =
        this.point || this.clientPointerRelativeToStage(px, py, stage);

      if (!this.point) this.point = pointer;

      const startPos = {
        x: pointer.x / oldScale - stage.x() / oldScale,
        y: pointer.y / oldScale - stage.y() / oldScale
      };

      const scaleBy = 1.01 + Math.abs(delta) / 100;
      const newScale = delta < 0 ? oldScale / scaleBy : oldScale * scaleBy;

      let height = newScale * stage.attrs.height;

      if (height > 300 && height < 5500) {
        stage.scale({ x: newScale, y: newScale });

        const newPosition = {
          x: (pointer.x / newScale - startPos.x) * newScale,
          y: (pointer.y / newScale - startPos.y) * newScale
        };

        stage.position(newPosition);
        stage.batchDraw();
      }

      this.lastDist = dist;
    }
  };

  pinchZoomTouchEventEnd = e => {
    this.lastDist = 0;
    this.point = undefined;
  };

  render() {
    let {
      imageSize,
      containerSize,
      imgObj,
      customElements,
      icons,
      move,
      color,
      colors,
      size,
      opacity,
      stageAction,
      createText,
      saveAnimation,
      newElement,
      isMobile
    } = this.state;
    let elements = this.buildElements();

    let getClickOnStage =
      notClickActions.indexOf(stageAction) === -1 ? true : false;

    let colorRgba = this.colorRgba(colors, color, opacity);

    return (
      <div style={{ overflow: 'hidden', position: 'relative' }}>
        <Buttons
          icons={icons}
          customElements={customElements}
          newElement={newElement}
          stageAction={stageAction}
          move={move}
          selectOpacity={this.props.selectOpacity}
          selectSize={this.props.selectSize}
          selectColor={this.props.selectColor}
          changeOpacity={data => this.changeOpacity(data)}
          changeColor={data => this.changeColor(data)}
          changeSize={data => this.changeSize(data)}
          addElement={(data, options) => this.addElement(data, options)}
          resizeStage={data => this.resizeStage(data)}
          moveImage={data => this.moveImage(data)}
          moveElement={data => this.moveElement(data)}
          select={data => this.select(data)}
          addDraw={data => this.addDraw(data)}
          addForm={data => this.addForm(data)}
          addLine={data => this.addLine(data)}
          addText={data => this.addText(data)}
          deleteElement={data => this.delete(data)}
        />

        <ButtonSave
          icons={icons}
          saveAnimation={saveAnimation}
          saveChanges={data => this.saveChanges(data)}
        />

        <div
          ref={this.cc}
          id={'canvas-cc'}
          className={'canvas-container'}
          style={{
            ...styles.canvas_cc,
            overflow: 'hidden',
            height: containerSize.h,
            width: containerSize.w
          }}
        >
          {imageSize.w && imageSize.h ? (
            <Stage
              ref={this.stage}
              draggable={move}
              key={'canvas-stage'}
              width={imageSize.w}
              height={imageSize.h}
              // used for handle events like select, delete, transform on the forms inside
              onClick={getClickOnStage ? this.onClickStage : () => {}}
              onTap={getClickOnStage ? this.onClickStage : () => {}}
              // used to finish form paint
              onDblClick={this.stageDblClick}
              onDblTap={this.stageDblClick}
              // used to draw a line and form
              onMouseDown={
                !getClickOnStage && !isMobile ? this.stageMouseDown : undefined
              }
              onMouseMove={
                !getClickOnStage && !isMobile ? this.stageMouseMove : undefined
              }
              onMouseUp={
                !getClickOnStage && !isMobile ? this.stageMouseUp : undefined
              }
              // used in mobile and tablets
              onTouchStart={
                !getClickOnStage && isMobile ? this.stageMouseDown : undefined
              }
              onTouchMove={
                !getClickOnStage && isMobile ? this.stageMouseMove : undefined
              }
              onTouchEnd={
                !getClickOnStage && isMobile ? this.stageMouseUp : undefined
              }
              onTouchCancel={
                !getClickOnStage && isMobile ? this.stageMouseUp : undefined
              }
              onContentWheel={this.pinchZoomWheelEvent}
              onContentTouchMove={this.pinchZoomTouchEvent}
              onContentTouchEnd={this.pinchZoomTouchEventEnd}
            >
              <Layer ref={this.layer}>
                {imgObj ? (
                  <ImgKonva
                    x={0}
                    y={0}
                    image={imgObj}
                    width={imageSize.w}
                    height={imageSize.h}
                  />
                ) : null}

                {elements}

                <Line x={0} y={0} points={[0, 0]} />

                {stageAction === 'line' && newElement.type === 'line' ? (
                  <Line
                    x={newElement.startX}
                    y={newElement.startY}
                    points={[0, 0, newElement.endX, newElement.endY]}
                    tension={0}
                    closed
                    stroke={newElement.options.color || colorRgba}
                    strokeWidth={newElement.options.size || size}
                    lineCap={'round'}
                    lineJoin={'round'}
                  />
                ) : null}
                {stageAction === 'form' && newElement.type === 'form' ? (
                  <Line
                    x={newElement.startX}
                    y={newElement.startY}
                    points={newElement.points}
                    tension={0}
                    closed
                    stroke={newElement.options.color || colorRgba}
                    strokeWidth={2}
                    lineCap={'round'}
                    lineJoin={'round'}
                    fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                    fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                    fillLinearGradientColorStops={[
                      0,
                      newElement.options.color || colorRgba
                    ]}
                  />
                ) : null}
              </Layer>
            </Stage>
          ) : null}

          {createText && createText.name ? (
            <div
              style={{
                ...styles.create_text,
                top: createText.top,
                left: createText.left
              }}
            >
              <textarea
                autoFocus
                style={{ ...styles.create_text_area }}
                value={createText.value}
                onKeyDown={this.finishTextEdition}
                onChange={e => this.updateText(e.target.value)}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  cleanNewElement(type, options) {
    this.setState(ps => ({
      ...ps,
      newElement: {
        type,
        options: {
          ...options
        }
      }
    }));
  }

  /** Get mouse position inside Stage */
  mouseRelativePosition() {
    // the function will return pointer position relative to the passed node
    var transform = this.stage.current.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();
    // get pointer (say mouse or touch) position
    var pos = this.stage.current.getPointerPosition();
    // now we find relative point
    let point = transform.point(pos);
    return { x: point.x, y: point.y };
  }

  changeColor = value => {
    if (this.lastLine) this.lastLine.color = this.colorRgba();
    this.setState(ps => ({ ...ps, color: value }));
  };

  changeSize = value => {
    if (this.lastLine) this.lastLine.size = value;
    this.setState(ps => ({ ...ps, size: value }));
  };

  changeOpacity = value => {
    this.setState(ps => ({ ...ps, opacity: value }));
  };

  stageMouseDown = e => {
    let { newElement, stageAction } = this.state;
    if (stageAction === 'line') {
      if (newElement && newElement.type === 'line' && newElement.startX) {
        // already exist the element we need to create the new line inside Stage
        this.addNewElement({
          x: newElement.startX,
          y: newElement.startY,
          points: [0, 0, newElement.endX, newElement.endY],
          key: 'line-' + newElement.startX + '-' + newElement.startY
        });
        this.cleanNewElement('line');
      } else {
        // not exist the element we need to create the object
        let mrp = this.mouseRelativePosition();
        this.setState(ps => ({
          ...ps,
          newElement: {
            ...ps.newElement,
            startX: mrp.x,
            startY: mrp.y,
            endX: 0,
            endY: 0
          }
        }));
      }
    } else if (stageAction === 'form') {
      if (newElement && newElement.type === 'form' && newElement.startX) {
        // already exist the element we need to add points inside the line
        newElement.points.push(newElement.endX);
        newElement.points.push(newElement.endY);

        this.setState(ps => ({
          ...ps,
          newElement: {
            ...ps.newElement,
            points: newElement.points
          }
        }));
      } else {
        // not exist the element we need to create the object
        let mrp = this.mouseRelativePosition();
        this.setState(ps => ({
          ...ps,
          newElement: {
            ...ps.newElement,
            startX: mrp.x,
            startY: mrp.y,
            endX: 0,
            endY: 0,
            points: [0, 0, 0, 0]
          }
        }));
      }
    } else if (stageAction === 'draw') {
      if (newElement && newElement.type === 'draw') {
        this.isPaint = true;
        let pos = this.mouseRelativePosition();
        let layer = this.layer.current;

        this.lastLine = new Konva.Line({
          stroke: this.colorRgba(),
          strokeWidth: this.state.size,
          lineCap: 'round',
          lineJoin: 'round',
          points: [pos.x, pos.y]
        });
        layer.add(this.lastLine);

        this.setState(ps => ({
          ...ps,
          newElement: {
            ...ps.newElement,
            startX: pos.x,
            startY: pos.y,
            points: [0, 0, 0, 0]
          }
        }));
      }
    }
  };

  stageMouseMove = e => {
    let { newElement, stageAction } = this.state;
    if (
      stageAction === 'line' &&
      newElement.type === 'line' &&
      newElement.startX
    ) {
      let mrp = this.mouseRelativePosition();
      this.setState(ps => ({
        ...ps,
        newElement: {
          ...ps.newElement,
          endX: mrp.x - ps.newElement.startX,
          endY: mrp.y - ps.newElement.startY
        }
      }));
    } else if (
      stageAction === 'form' &&
      newElement.type === 'form' &&
      newElement.startX
    ) {
      let points = newElement.points;
      let mrp = this.mouseRelativePosition();
      points[points.length - 2] = mrp.x - newElement.startX;
      points[points.length - 1] = mrp.y - newElement.startY;

      this.setState(ps => ({
        ...ps,
        newElement: {
          ...ps.newElement,
          endX: mrp.x - ps.newElement.startX,
          endY: mrp.y - ps.newElement.startY,
          points
        }
      }));
    } else if (stageAction === 'draw' && newElement.type === 'draw') {
      if (!this.isPaint) {
        return;
      }
      const pos = this.mouseRelativePosition();
      var newPoints = this.lastLine.points().concat([pos.x, pos.y]);
      this.lastLine.points(newPoints);
      this.layer.current.batchDraw();
    }
  };

  stageMouseUp = e => {
    let { newElement, stageAction } = this.state;
    if (stageAction === 'draw' && newElement.type === 'draw' && this.lastLine) {
      this.addNewElement({
        points: this.lastLine.attrs.points,
        key: 'draw-' + newElement.startX + '-' + newElement.startY
      });
      this.isPaint = false;
      this.cleanNewElement('draw');
    }
  };

  /** Used to finish form creation process and zoom in */
  stageDblClick = e => {
    let { newElement, stageAction } = this.state;

    if (stageAction === 'form') {
      if (newElement && newElement.type === 'form' && newElement.startX) {
        newElement.points.pop();
        newElement.points.pop();

        this.addNewElement({
          x: newElement.startX,
          y: newElement.startY,
          points: newElement.points,
          key: 'form-' + newElement.startX + '-' + newElement.startY
        });
        this.cleanNewElement('form');
      }
    }
  };

  moveImage() {
    this.setState(ps => ({ ...ps, move: !ps.move, stageAction: '' }));
  }

  addText() {
    let stageAction = this.state.stageAction === 'add' ? '' : 'add';
    if (stageAction)
      this.updateState('add', {
        type: 'text',
        options: { text: '' }
      });
    else
      this.setState(ps => ({
        ...ps,
        createText: null,
        stageAction: '',
        newElement: null,
        selectedShape: '',
        move: false
      }));
  }

  addLine() {
    let stageAction = this.state.stageAction === 'line' ? '' : 'line';
    this.updateState(stageAction, { type: 'line', options: {} });
  }

  addForm() {
    let stageAction = this.state.stageAction === 'form' ? '' : 'form';
    this.updateState(stageAction, { type: 'form', options: {} });
  }

  addDraw() {
    let stageAction = this.state.stageAction === 'draw' ? '' : 'draw';
    this.updateState(stageAction, { type: 'draw', options: {} });
  }

  transform() {
    let stageAction = this.state.stageAction === 'transform' ? '' : 'transform';
    this.updateState(stageAction);
  }

  delete() {
    let stageAction = this.state.stageAction === 'delete' ? '' : 'delete';
    this.updateState(stageAction);
  }

  select() {
    let stageAction = this.state.stageAction === 'select' ? '' : 'select';
    this.updateState(stageAction);
  }

  moveElement() {
    let stageAction =
      this.state.stageAction === 'moveElement' ? '' : 'moveElement';
    this.updateState(stageAction);
  }

  updateState(stageAction, data) {
    this.setState(ps => ({
      ...ps,
      stageAction,
      newElement: data,
      selectedShape: '',
      move: false
    }));
  }

  cleanAll = e => {
    this.setState(ps => ({
      ...ps,
      stageAction: '',
      newElement: {}
    }));
  };
}

export default Container;
